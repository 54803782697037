import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/admin/member/risk/roster/page',
    method: 'get',
    params
  })
}
// 新增白名单
export function addRosterAPI(data) {
  return http({
    url: '/admin/member/risk/roster/add',
    method: 'post',
    data
  })
}
// 编辑白名单
export function editRosterAPI(data) {
  return http({
    url: '/admin/member/risk/roster/edit',
    method: 'post',
    data
  })
}
// 详情
export function getRosterDetailAPI(params) {
  return http({
    url: '/admin/member/risk/roster/detail',
    method: 'get',
    params
  })
}
// 删除
export function delRosterAPI(data) {
  return http({
    url: '/admin/member/risk/roster/delete',
    method: 'post',
    data
  })
}
// 加入黑名单
export function changeRosterAPI(data) {
  return http({
    url: '/admin/member/risk/roster/move',
    method: 'post',
    data
  })
}
